// Map controls
.main_map .leaflet-top {
	display: flex;
	width: 100%;
	padding-right: 16px;

	.geosearch {
		height: 33px;
		flex-grow: 1;

		a.leaflet-bar-part {
			display: none;
		}

		form {
			height: 100%;
			position: relative;
			display: flex;
			align-items: center;

			input {
				height: 100%;
				outline: none;
				border: none;
				font-size: 12px;
				border: none;
				border-radius: 0 4px 4px 0;
				text-indent: 8px;
				flex-grow: 1;
			}

			button {
				background: none;
				outline: none;
				border: none;
			}

			.results {
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				z-index: 1;
				margin-top: 5px;
				overflow: auto;
				max-height: 200px;
				background-color: white;
				border-radius: 3px;
				cursor: pointer;
				padding: 0 6px;

				&::-webkit-scrollbar {
					width: 10px;
				}
				&::-webkit-scrollbar-track {
					background: #f1f1f1;
				}
				&::-webkit-scrollbar-thumb {
					background: #888;
				}
				&::-webkit-scrollbar-thumb:hover {
					background: #555;
				}

				> div {
					padding: 4px 0;

					&:not(:last-child) {
						border-bottom: thin solid rgb(189, 189, 189);
					}
				}
			}
		}

		a.reset {
			position: absolute !important;
			right: 0px !important;
			top: 0px !important;
		}
	}
}

.navigator {
	position: absolute;
	z-index: 400;
	top: 18%;
	left: 10px;

	button {
		outline: none;
		border: thin solid rgb(184, 184, 184);
		background-color: white;
		padding: 3.5px 4px;
		border-radius: 4px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		> div {
			height: 24px;
			width: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		> svg {
			width: 24px;
			height: 24px;
		}
	}
}
