.project-info-page {
	max-width: 100%;
}

.ant-table {
	width: 100%;

	.ant-table-cell {
		padding: 12px;
	}
}
