@import '../../../../styles/variables';

.ant-layout {
	min-height: 100%;
}

main.ant-layout-content {
	.custom-page-header {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	form .btns-group {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-top: 48px;
	}
}

.trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

.trigger:hover {
	color: #1890ff;
}

aside.ant-layout-sider > .ant-layout-sider-children {
	position: fixed;
	width: inherit;
	display: flex;
	flex-direction: column;
	overflow: auto;

	.ant-menu {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		// .map-link {
		// 	margin-top: auto;
		// }

		&.ant-menu-sub.ant-menu-hidden {
			display: none;
		}
	}
}

.logo {
	display: block;
	width: 90%;
	margin: 12px auto 0;
}

.logo img {
	width: 100%;
}

.site-layout .site-layout-background {
	background: #fff;
}

.user-avatar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin: 24px 0;
}

.user-detail-headings {
	color: #808080;
	margin-left: 10px;
}

.site-page-header {
	padding-top: 10px;
}

.tab-menu {
	margin-bottom: 16px;
	border-bottom: thin solid rgb(212, 212, 212);

	button {
		background: none;
		outline: none;
		border: none;
		cursor: pointer;
		padding: 8px 16px;
		font-size: 1em;
		transition: all ease-in-out;

		&.active {
			font-weight: 700;
			color: $primary-color;
		}
	}
}

td span {
	&.status-active {
		color: #38cf2b;
	}
	&.status-inactive {
		color: #868686;
	}
	&.status-blocked {
		color: #f24867;
	}
}

.ant-table-thead > tr > .ant-table-cell,
.ant-table-column-title {
	font-weight: 700;
}

.auto-gen-btn {
	display: flex;
	justify-content: flex-end;
}

.ant-form-item-label {
	font-weight: 600;
}

.custom-scroll-bar {
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

@media (max-width: 578px) {
	aside.ant-layout-sider {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 55px !important;
		width: 100% !important;
		z-index: 1;

		a.logo,
		.user-avatar {
			display: none;
		}

		> .ant-layout-sider-children {
			.ant-menu {
				height: 100%;
				display: grid;
				grid-template-columns: 0.15fr repeat(var(--steps), 1fr) 0.15fr;

				li:not(.map-link) {
					margin: 0 !important;
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 10px 0 !important;

					span {
						height: auto !important;
					}

					span.anticon svg {
						width: 20px !important;
						height: 20px !important;
					}

					span.ant-menu-title-content {
						margin: -8px 0 0 0;
						font-size: 0.75em;
					}
				}

				.map-link {
					position: fixed;
					bottom: 45px;
					right: -3.5%;
					z-index: 4;
					margin-top: unset;
					width: max-content;

					svg {
						width: 24px;
						height: 24px;
					}

					span {
						display: none;
					}
				}
			}
		}
	}

	.site-layout-background {
		max-width: 100% !important;
		padding: 0 4% !important;

		a.logo {
			width: 35% !important;
			max-height: 90% !important;
			width: auto;
			margin: 0;
		}

		.ant-space {
			button span {
				width: 100%;
			}
		}
	}

	main.ant-layout-content {
		margin: 0 !important;
		padding: 0 0 20% 0 !important;

		.custom-page-header {
			flex-direction: column;
			align-items: flex-start;
			padding: 10px 3%;

			.site-page-header {
				padding: unset !important;
			}

			.ant-space {
				align-self: flex-end;
			}
		}

		form .btns-group {
			padding-bottom: 80px;
		}
	}

	.site-page-header {
		padding: 10px 3% 24px !important;
	}

	.ant-form-item {
		margin-bottom: 16px;

		.ant-form-item-label {
			padding-bottom: 2px;
		}
	}

	.auto-gen-wrapper {
		position: relative;

		.auto-gen-btn {
			position: absolute;
			right: 0;
			z-index: 1;

			button {
				padding-right: 0;
			}
		}
	}
}
