.earning-page {
	.redeem-card {
		width: 200px;
		margin-left: 24px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		padding: 16px;
		border-radius: 8px;
		box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.25);
		position: relative;
		background-color: white;

		h4 {
			margin: 0;
		}

		img {
			position: absolute;
			width: 34px;
			top: 8px;
			right: 16px;
		}

		div {
			align-self: center;
			display: flex;
			align-items: flex-end;
			gap: 8px;
			color: black;
			font-size: 1.25em;

			span:last-child {
				color: red;
				font-weight: 700;
				// background-image: url(../../../assets/coins_bg.png);
			}
		}

		button {
			align-self: flex-end;
			border-radius: 8px;
			cursor: pointer;
		}
	}

	@media (max-width: 578px) {
		padding: 0 4% !important;

		.redeem-card {
			margin-left: 2%;
		}
	}
}
