.poxes-page {
	max-width: 960px;

	.poxes-content-wrapper {
		margin: 0 20px;
	}
}

@media (max-width: 578px) {
	.poxes-page {
		padding-bottom: 100px;
		max-width: 100%;

		.create-pox-btn {
			position: fixed;
			bottom: 65px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 3;
		}

		.poxes-content-wrapper {
			max-width: 100%;
			margin: 0;
			background-color: white;

			.ant-table {
				width: 100%;

				tr {
					display: grid;
					grid-template-columns: 20% 38% 20% 22%;
				}

				.ant-table-cell {
					padding: 12px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.del-button {
				display: none !important;
			}
		}
	}
}
