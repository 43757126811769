.message-box {
	border: thin solid #808080;
	border-radius: 10px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;

	.message-header {
		background-color: rgba(23, 24, 92, 0.085);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 6px 16px;
	}

	.message-body {
		flex-grow: 1;
		overflow-y: auto;
		padding: 5px;

		> *::-webkit-scrollbar {
			width: 5px;
		}
		> *::-webkit-scrollbar-track {
			background: #f1f1f1;
		}
		> *::-webkit-scrollbar-thumb {
			background: #888;
		}

		.messages-view {
			padding: 16px 4px;
			border: thin solid rgba(116, 116, 116, 0.5);
			border-radius: 8px;
			min-height: 100%;

			.ant-list-items {
				display: flex;
				flex-direction: column;

				span.chat-day {
					width: 100%;
					font-size: 1em;
					font-weight: 700;
					text-align: center;
					margin: 24px 0;
					text-transform: uppercase;
					border-bottom: thin solid rgb(218, 218, 218);

					&:first-child {
						margin-top: 0;
					}
				}

				.message {
					max-width: 60%;
					display: flex;
					gap: 5px;

					> div {
						background-color: rgb(102, 28, 28);
						color: white;
						border-radius: 0.75em;
						border-top-left-radius: 0;
						font-size: 0.85em;
						margin-bottom: 0.75em;
						overflow: hidden;

						&.text {
							padding: 8px 12px;
						}

						&.file {
							display: flex;
							flex-direction: column;
							width: 200px;

							> div {
								width: 100%;
								background-color: rgb(201, 48, 48);
								padding-right: 12px;
								display: flex;
								align-items: center;
								cursor: pointer;
								transition: 0.25s;

								&:hover {
									opacity: 0.85;
								}

								a,
								> span {
									padding: 12px;
									color: inherit;
									flex-grow: 1;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}
							}

							> span {
								padding: 4px 12px;
								font-size: 0.85em;
							}
						}
					}

					span.time {
						font-size: 0.75em;
						align-self: flex-end;
					}

					&.sent {
						flex-direction: row-reverse;
						align-self: flex-end;

						> div {
							background-color: rgb(27, 27, 56);
							border-top-left-radius: 0.75em;
							border-top-right-radius: 0;

							&.file > div {
								background-color: rgb(57, 57, 179);
							}
						}
					}
				}
			}
		}

		> button {
			border: thin solid rgb(255, 255, 255);
			background-color: rgb(119, 119, 119);
			box-shadow: 3px 0px 5px rgba(116, 116, 116, 0.5);
		}
	}

	.enter-message {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background-color: rgba(23, 24, 92, 0.08);
		padding: 12px;

		.ant-upload-list {
			position: absolute;
			background-color: white;
			bottom: 120%;
			right: 0;
			max-width: 250px;
			border-radius: 0.25rem;

			.ant-upload-list-text-container {
				padding: 0 8px 4px;
			}
		}
	}
}

.inbox {
	background-color: white;
	height: 60%;
	border-radius: 5px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	.ant-list-header {
		padding: 12px 3%;
	}

	.ant-list-item {
		padding: 12px 2%;

		.unread {
			width: 10px;
			height: 10px;
			background-color: red;
			border-radius: 50%;
		}
	}
}

@media (max-width: 578px) {
	.site-page-header {
		padding: 5px 8px !important;
	}

	.inbox {
		position: absolute;
		top: 65px;
		left: 0;
		width: 100%;
		height: calc(100% - 65px - 58px) !important;
		z-index: 1;
		transition: left 0.25s linear;
		border-radius: 0;

		&::-webkit-scrollbar {
			width: 5px;
		}
	}

	.message-box {
		border-radius: 0;
		border-left: none;
		border-right: none;
	}
}
