$Primary-Color: #4274c4;
$Danger-Color: #f24867;
$Warning-Color: #b99908;

.home-page-admin {
	padding: 0 8px;

	.poxes-col {
		background: white;
		height: 160px;
		border-radius: 10px;
		padding: 16px;
		display: flex;
		justify-content: space-evenly;

		h5 {
			text-align: center;
		}

		h2,
		h3 {
			color: #042954;
			margin: 0;
		}

		div.stats-wrapper {
			display: flex;
			gap: 16px;

			.stats-card {
				border-radius: 8px;
				text-align: center;
				width: 100%;
				max-width: 100px;
				margin: auto;
				padding: 8px;
				box-shadow: 2px 2px 0 1px rgba(23, 24, 92, 0.2),
					-1px -1px rgba(23, 24, 92, 0.1);
				color: unset;

				span {
					text-transform: capitalize;
				}
			}
		}
	}

	.sales-container {
		background: white;
		height: 100%;
		min-height: 280px;
		display: flex;
		flex-direction: column;
		padding: 8px;

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 8px;
		}

		.content-area {
			flex-grow: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			.ant-row {
				padding-top: 10px;
				width: 100%;
				height: 100%;
			}

			.pox-info {
				width: 80px;

				h5 {
					position: relative;
					left: 15px;
					margin-bottom: 4px;

					&:before {
						content: '';
						position: absolute;
						top: 50%;
						left: -15px;
						transform: translateY(-50%);
						width: 12px;
						height: 12px;
						border-radius: 50%;
					}
				}

				span {
					display: block;
					width: 100%;
					padding: 4px 8px;
					font-size: 1em;
				}

				&.flat {
					h5::before {
						background-color: $Primary-Color;
					}
					span {
						color: $Primary-Color;
						background-color: #4274c414;
					}
				}
				&.plot {
					h5::before {
						background-color: $Warning-Color;
					}
					span {
						color: $Warning-Color;
						background-color: #b9990814;
					}
				}
				&.estab {
					h5::before {
						background-color: $Danger-Color;
					}
					span {
						color: $Danger-Color;
						background-color: #f2486714;
					}
				}
			}

			.recharts-responsive-container {
				margin-left: -25px;

				.recharts-legend-wrapper {
					width: 100% !important;
					bottom: -10px !important;
					left: 25px !important;
				}
			}
		}
	}
}

@media (max-width: 578px) {
}

@media (max-width: 578px) {
	.home-page-admin {
		padding: 0 3% 8px !important;

		.ant-col {
			&:nth-child(3) .poxes-col {
				height: 100px;
			}
		}
	}

	.ant-table {
		.ant-table-cell {
			padding: 12px;
		}
	}
}
