.admin-poxes-table {
	max-width: 1080px;
}

@media (max-width: 578px) {
	.admin-poxes-table {
		.ant-table {
			width: 100%;

			// tr {
			// 	display: grid;
			// 	grid-template-columns: 1fr 0.75fr 1.5fr 1fr;
			// }

			.ant-table-cell {
				padding: 12px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
