.home-page {
	.statistics-wrapper {
		display: grid;
		grid-template-columns: repeat(4, 145px);
		gap: 24px;
		padding: 0 24px;
	}

	.statistic-card {
		width: 145px;
		height: 145px;
		border: thin solid;
		border-radius: 50%;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 16px;
		box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.25);
	}

	.redeem-card {
		width: 200px;
		margin-top: 30px;
		margin-left: 24px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		padding: 16px;
		border-radius: 8px;
		box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.25);
		position: relative;
		background-color: white;

		h4 {
			margin: 0;
		}

		img {
			position: absolute;
			width: 34px;
			top: 8px;
			right: 16px;
		}

		div {
			align-self: center;
			display: flex;
			align-items: flex-end;
			gap: 8px;
			color: black;
			font-size: 1.25em;

			span:last-child {
				color: red;
				font-weight: 700;
				// background-image: url(../../../assets/coins_bg.png);
			}
		}

		button {
			align-self: flex-end;
			border-radius: 8px;
			cursor: pointer;
		}
	}

	.map-link-div2 {
		display: none;
	}
}

@media (max-width: 578px) {
	.home-page {
		padding: 0 4% !important;

		.statistics-wrapper {
			padding: 0 2%;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
		}

		.statistic-card {
			span {
				font-size: 1.5em !important;
			}
		}

		.redeem-card {
			margin-left: 2%;
		}

		.map-link-div2 {
			position: relative;
			height: 15vh;
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			.map-link {
				display: flex;
				align-items: center;
				margin-right: 50px;

				svg {
					width: 30px;
					height: 30px;
				}
			}
		}
	}
}

@media (max-width: 360px) {
	.home-page {
		.statistic-card {
			width: 120px;
			height: 120px;

			h4 {
				font-size: 1.1em !important;
			}
		}
	}
}
