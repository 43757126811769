.row-styles {
	max-width: 1024px;

	.map-wrapper {
		width: 100%;
		height: 100%;
		max-width: 360px;
		max-height: 360px;
		border: thin solid #808080;
	}

	.images-wrapper {
		border: thin solid #808080;
		border-radius: 12px;
		padding: 7px 5px;
		width: 100%;

		.pox-img {
			border: thin solid #808080;
			border-radius: 8px;
		}

		.more {
			width: 100%;
			height: 100%;
			border: thin solid #808080;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

@media (max-width: 578px) {
	.row-styles {
		padding: 0 3%;
	}
}
