// ant design
@import '~antd/dist/antd.css';

// custom variables
@import 'variables';

html,
body {
	height: 100%;
	font-family: $main-family;
	background-color: #f0f2f5;

	#root {
		height: 100%;
		background-color: #f0f2f5;
	}

	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

// custom menu
ul.custom-menu {
	padding: 5px;

	> li {
		margin: 5px 0;
	}
}
