.custom-modal-wrapper {
	position: fixed;
	z-index: 1001;

	> button.mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(131, 131, 131, 0.247);
		border: none;
		outline: none;
		cursor: default;
	}

	> .child-wrapper {
		&::before {
			content: '';
			position: absolute;
			width: 24px;
			height: 24px;
			background-color: #fff;
			top: -12px;
			right: 20%;
			transform: rotate(45deg);
		}

		position: absolute;
		top: 50%;
		right: 50%;
		transform: translateX(30%);
		background-color: #fff;
		padding: 4px 0;
		border-radius: 8px;
		min-width: 300px;
	}
}
