@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700);

// color schemes
$primary-color: #4274c4;
$secondary-color: '';
$accent-color: #f24867;
$linear-color: '';
$bg-main-color: #042954;
$bg-alt-color: #f2f3f8;
$white: #ffffff;
$black: #000000;

// font
$main-family: 'Source Sans Pro', sans-serif;
