.coming-soon {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;

	h1 {
		font-family: 'Nunito Sans';
		font-style: normal;
		font-weight: 700;
		font-size: 49px;
		line-height: 55px;
		color: #4274c4;
		text-align: center;
	}

	img {
		width: 80%;
		height: 80%;
	}
}

@media (max-width: 578px) {
	.coming-soon {
		h1 {
			font-size: 3em;
			line-height: initial;
		}
	}
}
