.section-auth {
	display: flex;
	background: linear-gradient(#1c2167, #2b468d, #3964b3);
	height: 100%;
	position: relative;
	overflow: hidden;

	&::after {
		position: absolute;
		content: '';
		height: 150vh;
		min-height: 1200px !important;
		top: -25vh;
		right: 0;
		width: 70vw;
		background-color: #ddd;
		border-radius: 80% 0% 0 80%;
	}

	.section-logo {
		position: absolute;
		top: 20px;
		left: 5%;
		z-index: 3;

		img {
			height: 48px;
			margin-left: -3%;
		}
	}

	.section-forms {
		width: 100%;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10%;

		/* this code for glob image  */
		.view-img {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 85%;
				max-width: 600px;
			}
		}

		/* this is right section  */
		.right {
			width: 60%;
			background-color: transparent;
			max-width: 600px;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;

			.header-text {
				color: #2b468d;
				font-weight: 600;
				font-size: 2.5em;
			}

			.login-btn {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 48px;
				padding: 0 2%;

				button {
					width: 350px;
					max-width: 100%;
					color: #042954;
					font-size: 1.35em;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.divider {
					position: relative;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					&::after {
						content: '';
						position: absolute;
						width: 100%;
						height: 1px;
						background: #808080;
						z-index: -1;
					}

					span {
						position: relative;
						background-color: #ddd;
						padding: 0 5%;
						font-weight: 600;
						font-size: 1em;
					}
				}
			}

			.map-link {
				display: flex;
				align-items: center;
				gap: 3px;
				font-size: 1.5em;

				svg {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
}

@media (max-width: 578px) {
	.section-auth {
		background: #ddd;

		&::after {
			position: fixed;
			min-height: unset !important;
			height: 100%;
			width: 180%;
			top: 75%;
			left: 50%;
			right: unset;
			transform: translateX(-50%);
			background: linear-gradient(to bottom, #1c2167 5%, #2b468d, #3964b3);
			border-radius: 50% 50% 0 0;
		}

		.section-logo {
			top: 25px;

			img {
				height: auto;
				max-width: 175px;
			}
		}

		.section-forms {
			/* this code for glob image  */
			.view-img {
				position: fixed;
				top: 65%;

				img {
					width: 45% !important;
				}
			}

			/* this is form control section  */
			.right {
				width: 100%;
				justify-content: space-between;

				.header-text {
					margin-top: 20%;
				}

				.map-link {
					margin-bottom: 10%;
					color: #ffffff;

					svg path {
						fill: white;
					}
				}

				.login-btn {
					gap: 24px;
					margin-top: -35%;
				}
			}
		}
	}
}
