.sales-top {
	display: flex;
	align-items: center;
}
.sales-card {
	height: 80vh;
	width: 100%;
	border-radius: 10px;
	.sales-card-top {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 10px;
		.dashboard-heading2 {
			width: 30%;
		}
		.ant-space {
			width: 70%;
			.ant-space-item {
				width: 50%;
			}
		}
	}
	.map {
		width: 97%;
		height: 70vh;
		position: absolute;
		z-index: 1;
	}
}
@media (max-width: 1200px) {
	.sales-card {
		.map {
			width: 94%;
		}
	}
}
@media (max-width: 900px) {
	.sales-card {
		.map {
			width: 92%;
		}
	}
}
@media (max-width: 580px) {
	.sales-top {
		margin: 25px;
		.site-page-header {
			margin-left: 10px;
			padding: 0 !important;
		}
	}
	.sales-card {
		height: 70vh;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 35px;
		.sales-card-top {
			flex-wrap: wrap;
			.dashboard-heading2 {
				width: 100%;
			}
			.ant-space {
				width: 100%;
				.ant-space-item {
					width: 100%;
				}
			}
		}

		.map {
			width: 90%;
			margin-top: 20px;
			height: 52vh;
		}
	}
}
